app.factory('trainingProvidersModel', ['$http','$location', function($http,$location){
	trainingProvidersModel = {};

	trainingProvidersModel.getDistricts = function(){
		return $http.get(baseUrl+'training_providers/districts');
	}

	trainingProvidersModel.getSectors = function(){
		return $http.get(baseUrl+'training_providers/sectors');
	}

	trainingProvidersModel.getResult = function(searchKey){
		searchKey._token = csrfToken;
		return $http({
			header: {
				'Content-Type': 'application/json'
			},
			method: 'POST',
			url: baseUrl+'training_providers/result',
			//data: {searchKey,_token:csrfToken}
			data:searchKey
		});
	}

	return trainingProvidersModel;
}]);