app.factory('dashboardModel',function($http) {
	// body...
	dashboardModel = {};

	dashboardModel.searchData={};
	dashboardModel.searchDataCriteria={};

	dashboardModel.getBenificiary = function(data) {

		data._token = csrfToken;
		return $http({
			headers : {
				'Content-type' : 'application/json',
			},
			method : 'POST',
			url : baseUrl+'filter-benificiaries',
			data : data
		});
	}

	dashboardModel.specificStreams = function(qualfication_id){
		return $http({
			headers : {
				'Content-type' : 'application/json',
			},
			method : 'POST',
			url : baseUrl+'specific-streams',
			data : {_token:csrfToken,qualfication_id}
		});
	}

	return dashboardModel;
});