app.factory('jobfairModel', function($http) {
	jobfairModel = {};

	jobfairModel.getJobfairList = function(data) {
		data._token = csrfToken;
		return $http({
			headers: {
                'Content-Type': 'application/json'
            },
			url: baseUrl+'jobfair/employers-job-fair-list',
			method: 'POST',
			data: data
		})
	}

	jobfairModel.getJobFair = function() {
		// console.log("helllo");
		return $http.get(baseUrl+'jobfair/job-fair');
		
	}
    
	jobfairModel.getCounts = function() {
		// console.log("helllo");
		return $http.get(baseUrl+'jobfair/counts');
		
	}
	jobfairModel.getJobFairDetail = function(id){
		return $http.get(baseUrl+'jobfair/job-fair-detail',{params:{'id':id}});
	}

	jobfairModel.getEmployerPostedVacanciesForJobFair = function(id){
		return $http.get(baseUrl+'jobfair/employer-posted-vacancy',{params:{'id':id}});
	}

	jobfairModel.getJsJobfairs = function(){
		return $http.get(baseUrl+'jobfair/js-job-fairs');
	}
jobfairModel.getJsJobfairByDistrict = function(district_id){
		return $http.get(baseUrl+'jobfair/js-job-fair-by-district',{params:{'district_id':district_id}});
	}
	jobfairModel.getJsVacancies = function(job_fair_id){
		return $http.get(baseUrl+'jobfair/js-vacancies',{params:{'job_fair_id':job_fair_id}});
	}
jobfairModel.getJsVacanciesByDistrict = function(job_fair_id,district_id){
		return $http.get(baseUrl+'jobfair/js-vacancies-by-district',{params:{'job_fair_id':job_fair_id,'district_id':district_id}});
	}
	jobfairModel.applyVacancy = function(vacancy_id,job_fair_id) {
		var params = {};
		params.vacancy_id = vacancy_id;
		params.job_fair_id = parseInt(job_fair_id);
		params._token = csrfToken;

		return $http({
			headers: {
                'Content-Type': 'application/json'
            },
			url: baseUrl+'jobfair/apply-vacancy',
			method: 'POST',
			data: params
		})
	}

	jobfairModel.getEmployerDetail = function(id){
		return $http.get(baseUrl+'jobfair/employer-details',{params:{'employer_id':id}});
	}

   jobfairModel.getJobFairByDistrict = function(district_id){
		return $http.get(baseUrl+'jobfair/job-fair-by-district',{params:{'district_id':district_id}});
	}
	jobfairModel.getAllJobFair = function(){
		return $http.get(baseUrl+'jobfair/all-job-fair');
	}

	jobfairModel.getJobFairVacanciesByDistrict = function(job_fair_id,district_id,index,total_records) {
		var params = {};
		params.job_fair_id = parseInt(job_fair_id);
		params.district_id = district_id;
		params._token = csrfToken;
		params.index = index;
		params.total_records = total_records;

		return $http({
			headers: {
                'Content-Type': 'application/json'
            },
			url: baseUrl+'jobfair/job-fair-vacancies-by-district',
			method: 'POST',
			data: params
		})
	}

	jobfairModel.getJobFairVacancies = function(job_fair_id,index,total_records,district_id) {
		var params = {};
		params.job_fair_id = parseInt(job_fair_id);
		params._token = csrfToken;
		params.index = index;
		params.total_records = total_records;
		params.district_id = district_id;

		return $http({
			headers: {
                'Content-Type': 'application/json'
            },
			url: baseUrl+'jobfair/job-fair-vacancies',
			method: 'POST',
			data: params
		})
	}

	return jobfairModel;

});