app.factory('erReportModel', function($http) {
	erReportModel = {};

	erReportModel.baseUrl = baseUrl;

	erReportModel.getEmployerErReports = function(employerId) {
		return $http.get(baseUrl+'erReport/employer-er-reports',{params:{'empId':employerId}});
	}

	/*erReportModel.getPreviousData = function() {
		return $http.get(baseUrl+'erReport/previous-data');
	}*/

	erReportModel.getEmployerDetail = function(employerId) {
		return $http.get(baseUrl+'erReport/employer-detail',{params:{'empId':employerId}});
	}

	erReportModel.getEmployerPostedVacancy = function(data) {
		data._token = csrfToken;
		return $http({
			headers: {
                'Content-Type': 'application/json'
            },
			url: baseUrl+'erReport/employer-posted-vacancy',
			method: 'POST',
			data: data
		})
	}

	erReportModel.setErData = function(data) {
		data._token = csrfToken;;
		return $http({
			headers: {
                'Content-Type': 'application/json'
            },
			url: baseUrl+'erReport/er-data',
			method: 'POST',
			data: data
		})
	}

	//Admin ER-report Update
	erReportModel.adminErDataEdit = function(data) {
		data._token = csrfToken;;
		return $http({
			headers: {
                'Content-Type': 'application/json'
            },
			url: baseUrl+'erReport/admin-er-data-edit',
			method: 'POST',
			data: data
		})
	}

	erReportModel.erReportCheck = function(data) {
		data._token = csrfToken;;
		return $http({
			headers: {
                'Content-Type': 'application/json'
            },
			url: baseUrl+'erReport/er-report-check',
			method: 'POST',
			data: data
		})
	}

	erReportModel.getErDataForEdit = function(id){
		return $http.get(baseUrl+'erReport/er-data-for-edit',{params:{'id':id}});
	}

	erReportModel.getErEntryDate = function(id){
		return $http.get(baseUrl+'erReport/er-entry-date',{params:{'id':id}});
	}

	erReportModel.getFilledVacancySource = function(){
		return $http.get(baseUrl+'erReport/filled-vacancy-source');
	}

	erReportModel.getIncreaseReason = function(){
		return $http.get(baseUrl+'erReport/increase-reason');
	}

	erReportModel.getDecreaseReason = function(){
		return $http.get(baseUrl+'erReport/decrease-reason');
	}

	erReportModel.editErReport = function(data) {
		data._token = csrfToken;;
		return $http({
			headers: {
                'Content-Type': 'application/json'
            },
			url: baseUrl+'erReport/edit-er-report',
			method: 'POST',
			data: data
		})
	}

	erReportModel.getPreviousData = function(data) {
		data._token = csrfToken;;

		return $http({
			headers: {
                'Content-Type': 'application/json'
            },
			url: baseUrl+'erReport/previous-data',
			method: 'POST',
			data: data
		})
	}

	erReportModel.getLocalStorageValue = function(flag) {
		switch(flag){
			case 'empId':
					// code...
					var empId = localStorage.getItem('emploId');
					return employerId = (empId == null || empId == undefined || empId == '') ? 0 : parseInt(empId);
				break;
			case 'menuType':
					// code...
					var menuType = localStorage.getItem('menuType');
					return menuType = (menuType == null || menuType == undefined || menuType == '') ? '' : menuType;
				break;
			default:
				break;
		}
	}

	return erReportModel;

});