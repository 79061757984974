app.factory('userModel', ['$http','$stateParams','$state', '$location','$cookies', '$cookieStore','$q', function($http,$stateParams,$state,$location,$cookies,$cookieStore,$q) {
	userModel = {};

	
	userModel.addEmployer = function(employerData,contentType) { 
		//console.log(employerData);
		employerData._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType
			},
			/*headers: {
                'Content-Type': undefined
            },*/
			url: baseUrl+'user',
			method: 'POST',
			data: employerData
		})
	}

	userModel.addJfEmployer = function(employerData,contentType) { 
		//console.log(employerData);
		employerData._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType
			},
			/*headers: {
                'Content-Type': undefined
            },*/
			url: baseUrl+'user/addJfEmployer',
			method: 'POST',
			data: employerData
		})
	}

	userModel.addUrgentRequest = function(requestData,contentType) { 
		requestData._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType
			},
			url: baseUrl+'user/addUrgentRequest',
			method: 'POST',
			data: requestData
		})
	}

	userModel.getUrgentEmployer = function(requestData) { 
		return $http.get(baseUrl+'getUrgentEmployer/'+requestData);
	}


	userModel.exist_beneficiary_id = function(beneficiary_id){
		
		/*return $http({
			url: 'https://www.mssds.in/beneficiries/api_beneficiary_data/'+beneficiary_id,
			method: "GET",
		})*/

		return $http.get(baseUrl+'mssds_beneficiary_data/'+beneficiary_id);
	}

  userModel.updateDataForLastInsertedUser = function(iti_registration_no)
  {
  	return $http.get(baseUrl+'updateDataForLastInsertedUser/'+iti_registration_no);
  }

  userModel.updateDataForLastInsertedApleSarkarUser = function(mobile,aplesarkarregino)
  {
  	return $http.get(baseUrl+'updateDataForLastInsertedApleSarkarUser/'+mobile_no+'/'+aplesarkarregino);
  }
  //sendForPayment
  userModel.getIsUserRegisteredOrNot = function(registration_no){
  	return $http.get(baseUrl+'is_user_registered/'+registration_no);
  }
	userModel.generateOTP = function(obj){
		return $http.get(baseUrl+'generateOTP/'+obj);
	}

    userModel.getIitRegistrationData = function(registration_no)
    {
    	return $http.get(baseUrl+'itiUserData/'+registration_no);
    }

	userModel.authenticate_aadhar_no = function(aadhar_no){
		return true;
	}

	userModel.confirmOTP = function(OTP){
		return $http.get(baseUrl+'confirmOTP/'+OTP);
	}

	userModel.doLogin = function(data){
		//console.log(data); return;
		return 	$http({
				url:baseUrl+'auth',
				method: 'POST',
				data:data
			});
	}

	userModel.doApleSarkarLogin = function(data){
		//console.log(data); return;
		data._token = csrfToken;
		return 	$http({
				url:baseUrl+'authApleSarkar',
				method: 'POST',
				data:data
			});
	}

	userModel.ChangePassword = function(data){
		data._token = csrfToken;
		return 	$http({
				url:baseUrl+'ChangePassword',
				method: 'POST',
				data:data
			});
	}
	
	userModel.doLogout = function(data){
	$cookies.remove('auth');
	
	 return	$http({
			url: baseUrl+'logout',	
			method: 'POST',
			data:data
		});
	}

	userModel.forgotPassword = function(data){
		data._token = csrfToken;
		return	$http({
			url: baseUrl+'forgot_password',	
			method: 'POST',
			data:data
		});
	}

	userModel.search_profiles = function(data){
		data._token = csrfToken;
		return $http({
			url: baseUrl+'/postSearch_profile',
			method: 'POST',	
			data:data
		});
	}
	userModel.sendForApleSarkarPaymentVarification = function(aplesarkardata) {
		aplesarkardata._token = csrfToken;
		return $http({
			
			url: baseUrl+'sendForApleSarkarPaymentVarification',
			method: 'POST',
			data: aplesarkardata
		})
	}
	userModel.redirectingToPayment = function(userdata) {
		userdata._token = csrfToken;
		return $http({
			
			url: baseUrl+'redirectingToPayment',
			method: 'POST',
			data: userdata
		})
	}
	userModel.deleteApleSarkarData =  function(aplesarkardata) {
		aplesarkardata._token = csrfToken;
		return $http({
			
			url: baseUrl+'deleteApleSarkarData',
			method: 'POST',
			data: aplesarkardata
		})
	}

	userModel.getUserObj = function(){
		var userObj = angular.fromJson($cookies.get('auth'));
		// console.log(userObj);
		return userObj;
	}
   
	userModel.getAuthStatus = function(){
			/*$http.get(baseUrl+'master/check-session/').success(function(response){
				console.log(response+"jdfls");
				
				if(response == "Fail"){
					return false;
				} else {
					return true;
				}
		  });*/
		var status = $cookies.get('auth');
		if(status){
			return true;
		}else{
			return false;
		}
	}

	userModel.get_user = function(id){
		return $http.get(baseUrl+'getShow/'+ id);
	}


	userModel.createAccount = function(userData){
			userData._token = csrfToken;
		    var formAction = baseUrl+'postStore';
			var formMethod = 'POST';
			return $http({
				headers: {
					'Content-Type': 'application/json'
						},
				url: formAction,
				method: formMethod,
				data: userData
		});
	}
	userModel.CreateCandiAccount = function(userData){
			userData._token = csrfToken;
		    var formAction = baseUrl+'postStoreCandidate';
			var formMethod = 'POST';
			return $http({
				headers: {
					'Content-Type': 'application/json'
						},
				url: formAction,
				method: formMethod,
				data: userData
		});
	}
	userModel.getJobseekerProfileDetails = function(){ 
		return $http.get(baseUrl+'job_seeker_profile_details');
	}

	userModel.getJobseekerProfileDetailsforEmp = function(beneficiary_id){ 
		////////alert(beneficiary_id);
		return $http.get(baseUrl+'job_seeker_profile_details_employer',{params:{"id":beneficiary_id}});
	}

	userModel.getEmployerOtherDetails = function(beneficiary_id){ 
		return $http.get(baseUrl+'employer_profile_details',{params:{"beneficiary_id":beneficiary_id}});
	}

	userModel.post_jobseeker_details = function(jobseekerData) {
		jobseekerData._token = csrfToken;
		return $http({
			/*headers: {
				'Content-Type': 'application/json'
			},*/
			url: baseUrl+'edit_jobseeker_details',
			method: 'POST',
			data: jobseekerData
		})
	}

	userModel.getJobseekerEducationDetails = function(){ 
		return $http.get(baseUrl+'job_seeker_education_details');
	}

	userModel.getJobseekerEducationDetailsforEmp = function(beneficiary_id){ 
		return $http.get(baseUrl+'job_seeker_education_details_employer',{params:{"id":beneficiary_id}});
	}

	userModel.getJobseekerSkillDetails = function(){ 
		return $http.get(baseUrl+'job_seeker_skill_details');
	}

	userModel.getJobseekerSkillDetailsforEmp = function(beneficiary_id){ 
		return $http.get(baseUrl+'job_seeker_skill_details_employer',{params:{"id":beneficiary_id}});
	}

	userModel.getJobseekerExperienceDetails = function(){ 
		return $http.get(baseUrl+'job_seeker_experience_details');
	}

	userModel.getJobseekerExperienceDetailsforEmp = function(beneficiary_id){ 
		return $http.get(baseUrl+'job_seeker_experience_details_employer',{params:{"id":beneficiary_id}});
	}

	userModel.getJobseekerOtherDetails = function(){ 
		return $http.get(baseUrl+'job_seeker_other_details');
	}

	userModel.getJobseekerOtherDetailsforEmp = function(beneficiary_id){ 
		//alert(beneficiary_id);
		return $http.get(baseUrl+'job_seeker_other_details_emloyer',{params:{"id":beneficiary_id}});
	}


	userModel.post_employer_details = function(employerData,contentType) { 
		//console.log(employerData);
		employerData._token = csrfToken;
		return $http({
			headers: {
                    'Content-Type': contentType
            },
			url: baseUrl+'edit_employer_details',
			method: 'POST',
			data: employerData
		})
	}

	userModel.authenticationUser = function(data,checkapi){
		console.log(checkapi);
		//var obj = {data:data,checkapi:checkapi}
		data.obj = checkapi;
		data._token = csrfToken;
		return $http({
			headers: {
                    'Content-Type': 'application/json'
            },
			url: baseUrl+'userAuthentication',
			method: 'POST',
			data: data
		})
	}

	 /*userModel.authenticationUser = function(data) {
                // the $http API is based on the deferred/promise APIs exposed by the $q service
                // so it returns a promise for us by default
                return $http({
						headers: {
			                    'Content-Type': 'application/json'
			            },
						url: baseUrl+'userAuthentication',
						method: 'POST',
						data: data
					})
                    .then(function(response) {
                       return response;
                    }, function(response) {
                        // something went wrong
                        return $q.reject(response.data);
                    });
            }*/
	
	userModel.getJobseekerViewProfileDetails = function(beneficiary_id){ 
		return $http.get(baseUrl+'view_jobseeker_profile_details',{params:{"beneficiary_id":beneficiary_id}});
	}


	userModel.getHotEmployersList = function() {
		return $http.get(baseUrl+ 'master/hot-employers-list').then(function(success){
			return success.data;
		});
	}

	userModel.getHomePageStats = function()
	{
		return $http.get(baseUrl+ 'master/home-page-stats');
	}
	userModel.uploadResume = function(resumeData){
		resumeData._token = csrfToken;
		return $http({
			headers: {
                'Content-Type': undefined
            },
			url: baseUrl+'jobseeker_resume',
			method: 'POST',
			data: resumeData
		})
	}

	userModel.uploadPhoto = function(resumeData){
		resumeData._token = csrfToken;
		return $http({
			headers: {
                'Content-Type': undefined
            },
			url: baseUrl+'jobseeker_photo',
			method: 'POST',
			data: resumeData
		})
	}

	userModel.getJobseekerCriteria = function() {
		return $http.get(baseUrl+'jobseeker_criteria');
	}

	userModel.getJobseekerUpdatedDetails = function() {
		return $http.get(baseUrl+'jobseeker_updated_details');
	}

	userModel.getEmployerUpdatedDetails = function() {
		return $http.get(baseUrl+'employer_updated_details');
	}

	userModel.doVerifiedAadhar = function(data){
		//return $http.get(baseUrl+'doVerifiedAadhar/'+id);
		data._token = csrfToken;
		return $http({
			headers: {
                'Content-Type': 'application/json'
            },
			url: baseUrl+'doVerifiedAadhar',
			method: 'POST',
			data: data
		})
	}

	userModel.setNewCredentials = function(data){
		//return $http.get(baseUrl+'setNewCredentials/'+id);
		data._token = csrfToken;
		return $http({
			headers: {
                'Content-Type': 'application/json'
            },
			url: baseUrl+'setNewCredentials',
			method: 'POST',
			data: data
		})
	}

	userModel.recoverAccount = function(id){
		return $http.get(baseUrl+'recoverAccount/'+id);
	}

	userModel.removeResume = function() {
		return $http.get(baseUrl+'jobseeker_resume_remove');
	}

	userModel.isMobExist = function(mobile_no, user_id){
		if(user_id==''){
			return $http.get(baseUrl+'isMobileExist/'+mobile_no);
		}else{
			return $http.get(baseUrl+'isMobileExist/'+mobile_no+'/'+user_id);
		}
		//return $http.get(baseUrl+'isMobileExist/'+mobile_no+'/'+user_id);
	}

	userModel.isMobExistUrgent = function(mobile_no){
		return $http.get(baseUrl+'isMobileExistUrgent/'+mobile_no);
	}

	userModel.isEmailExistUrgent = function(email){
		return $http.get(baseUrl+'isEmailExistUrgent/'+email);
	}

	userModel.isEmpTanExistUrgent = function(tan){
		return $http.get(baseUrl+'isEmpTanExistUrgent/'+tan);
	}

	userModel.isEmpGstExistUrgent = function(gst){
		return $http.get(baseUrl+'isEmpGstExistUrgent/'+gst);
	}

	userModel.isAadharExist = function(aadhar_no, user_id){
		if(user_id==''){
			return $http.get(baseUrl+'isAadharExist/'+aadhar_no);
		}else{
			return $http.get(baseUrl+'isAadharExist/'+aadhar_no+'/'+user_id);
		}
		
	}
	userModel.isAadharExistAndUserType = function(aadhar_no){
		
		return $http.get(baseUrl+'isAadharExistAndUserType/'+aadhar_no);	
		
	}

	userModel.isUserLessThanFourteen= function(dob){
		
		return $http.get(baseUrl+'isUserLessThanFourteen/'+dob);	
		
	}

	userModel.jsUserType = function(aadhar_no){
		return $http.get(baseUrl+'jsUserType/'+aadhar_no);
	}

	userModel.isEmailExist = function(email, user_id){
		if(user_id==''){
				return $http.get(baseUrl+'isEmailExist/'+email);
		}else{
			return $http.get(baseUrl+'isEmailExist/'+email+'/'+user_id);			
		}
		
	}

	userModel.getMSSDSUserData = function(aadhar_id){
		// return $http.get(baseUrl+'mssds/user-data',{params:{"key":bene_id}});
		return $http({
			headers:{
				'Content-type' : 'application/json'
			},
			method : 'POST',
			url : baseUrl+'mssds/user-data',
			data : {'key' : aadhar_id,'_token':csrfToken}
		});
	}

	userModel.change_password = function(data) { 
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'ChangePassword',
			method: 'POST',
			data: data
		})
	}

	userModel.getJobseekerEmployementCardDetails = function(){
		return $http.get(baseUrl+'jobseekerEmployementCardDetails');
	}

	userModel.getEmployerEmployementCardDetails = function(){
		return $http.get(baseUrl+'employerEmployementCardDetails');
	}

	userModel.totalJSProfiles = function(){
		return $http.get(baseUrl+'total_current_profiles');
	}

	userModel.getOrganizationType = function(param){
		return $http.get(baseUrl+'organizationType/'+param);
	}

	userModel.getRegisteredUnder = function(){
		return $http.get(baseUrl+'registeredUnder');
	}

	userModel.sendMailToEmployer = function(data){
		// console.log(data);
		return $http.get(baseUrl+'sendMailToEmployer/'+data);
		// return $http({
		// 	headers: {
		// 		'Content-Type': 'application/json'
		// 	},
		// 	url: baseUrl+'sendMailToEmployer',
		// 	method: 'POST',
		// 	data: data
		// })
	}
	userModel.getMobile = function(username){
		
		return $http.get(baseUrl+'userMobile/'+username);
		
	}
	userModel.getJsDetails = function(){
		return $http.get(baseUrl+'getJsDetails');
	}
	userModel.PteDetails = function(){
		return $http.get(baseUrl+'PteDetails');
	}
	userModel.jsPersonalInfoUpdate = function(bene_info, add_info, caste_info) {
		// return $http.get(baseUrl+'admin/user/users-data');
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'js-personal-info-update',
			method: 'POST',
			data: {'bene_info':bene_info, 'add_info':add_info, 'caste_info':caste_info,_token:csrfToken}
		})
	}

	userModel.jsEduDetailsUpdate = function(quali_info, vocational_courses, qual_test) {
		// return $http.get(baseUrl+'admin/user/users-data');
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'js-edu-details-update',
			method: 'POST',
			data: {'quali_info':quali_info, 'vocational_courses':vocational_courses, 'qual_test':qual_test,_token:csrfToken}
		})
	}

	userModel.jsSkillsUpdate = function(skills) {
		// return $http.get(baseUrl+'admin/user/users-data');
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'js-skills-update',
			method: 'POST',
			data: {'skills':skills, _token:csrfToken}
		})
	}

	userModel.jsWorkExpUpdate = function(work_exp,total_exp) {
		// return $http.get(baseUrl+'admin/user/users-data');
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'js-work-exp-update',
			method: 'POST',
			data: {'work_exp':work_exp,total_exp:total_exp, _token:csrfToken}
		})
	}

	userModel.jsOtherDetailsUpdate = function(preferred_locations, languages, other_details, disability, sports) {
		// return $http.get(baseUrl+'admin/user/users-data');
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'js-other-details-update',
			method: 'POST',
			data: {'preferred_locations':preferred_locations, 'languages':languages, 'other_details':other_details, 'disability':disability, 'sports':sports, _token:csrfToken}
		})
	}

	userModel.encryptCredentials = function(password){
		var key = CryptoJS.enc.Hex.parse("0123456789abcdef0123456789abcdef");
        var iv =  CryptoJS.enc.Hex.parse("abcdef9876543210abcdef9876543210");
        var encrypted = CryptoJS.AES.encrypt(password, key, {iv:iv});
        encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

        return encrypted;
	}

	userModel.duplicateUserCheck = function(data) {
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'duplicate-user-check',
			method: 'POST',
			data: data
		})
	}

	userModel.resetPwd = function(data) {
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'reset-password',
			method: 'POST',
			data: data
		})
	}

	userModel.getEmployerDetails = function(employerId){ 
		return $http.get(baseUrl+'employer-details',{params:{"employerId":employerId}});
	}
	

	return userModel;

}]);

