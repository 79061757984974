app.factory('eppModel',function($http) {
	// body...
	eppModel = {};

	eppModel.searchData={};
	eppModel.searchDataCriteria={};

	eppModel.getAllEppVacancies = function(data) {
		// body...
		data._token = csrfToken;

		return $http({
			headers : {
				'Content-type' : 'application/json',
			},
			method : 'POST',
			url : baseUrl+'epp/all-epp-vacancies',
			data : data
		});
	}

	eppModel.getAllEppVacancyBeneficiaries = function(data) {
		// body...
		
		data._token = csrfToken;
		return $http({
			headers : {
				'Content-type' : 'application/json',
			},
			method : 'POST',
			url : baseUrl+'epp/all-epp-vacancy-beneficiaries',
			data : data
		});
	}

	eppModel.setEppClaimInvoice = function(data) {
		// body...
		data._token = csrfToken;
		return $http({
			headers : {
				'Content-type' : 'application/json',
			},
			method : 'POST',
			url : baseUrl+'epp/set-epp-claim-invoice',
			data : data
		});
	}

	eppModel.getEppClaimDetail = function(data) {
		// body...
		data._token = csrfToken;
		return $http({
			headers : {
				'Content-type' : 'application/json',
			},
			method : 'POST',
			url : baseUrl+'epp/epp-claim-detail',
			data : data
		});
	}

	eppModel.getClaimEppCheck = function(vId,month,year) {
		// body...
		var data = {};
		data._token = csrfToken;
		data.vId = vId;
		data.month = month;
		data.year = year;

		return $http({
			headers : {
				'Content-type' : 'application/json',
			},
			method : 'POST',
			url : baseUrl+'epp/claim-epp-check',
			data : data
		});
	}

	return eppModel;
});