app.factory('masterModel', ['$http','$location','$state', function($http,$location,$state){

	masterModel = {};

	masterModel.salaryRanges = function(sector_type) {
		if(sector_type!=undefined){
			return $http.get(baseUrl+'master/salary-ranges/'+sector_type);
		}else{
			return $http.get(baseUrl+'master/salary-ranges');
		}
	}

	masterModel.getUploadsUrl = function() {
		// body...
		return $http.get(baseUrl+'master/uploads-url');
	}

	masterModel.getErModes = function() {
	 	return $http.get(baseUrl+'master/er-modes');
	}

	masterModel.sectors = function(obj) {
	 	return $http.get(baseUrl+'master/sectors',{params:{"obj":obj}});
	}

	masterModel.skills = function() {
		return $http.get(baseUrl+'master/skills');
	}

	masterModel.getAdvertisements = function() {
		return $http.get(baseUrl+'master/advertisements');
	}

	masterModel.getFilterAdvertisements = function(district_id) {
		//return $http.get(baseUrl+'master/filter-advertisements/',{params:{"district_id":district_id}});
		return $http.get(baseUrl+'master/filter-advertisements/'+district_id);
	}
	
	masterModel.educations = function(obj) {
		return $http.get(baseUrl+'master/educations',{params:{"obj":obj}});
	}

	masterModel.qualifications = function(obj) {
		return $http.get(baseUrl+'master/qualification-group',{params:{"obj":obj}});
	}

	masterModel.streams = function(obj) {
		return $http.get(baseUrl+'master/streams',{params:{"obj":obj}});
	}

	masterModel.compairHighestQualification = function(hqid,qid) {
		return $http.get(baseUrl+'master/compair-h-q/'+hqid+"/"+qid);
	}

	masterModel.courses = function(stream_id) {
		return $http.get(baseUrl+'master/courses/'+stream_id);
	}
    masterModel.getCitizenCharter= function() {
		return $http.get(baseUrl+'master/citizen-charter-list');
	}
	masterModel.Data_contact_us = function(pagename,langkey) {
	
		return $http.get(baseUrl+'master/data-contact-us',{params:{"pagename":pagename,"langkey":langkey}});
	}

	masterModel.specializations = function(obj){
		return $http.get(baseUrl+'master/specializations',{params:{"obj":obj}});
	}

	masterModel.state_list = function() {
		return $http.get(baseUrl+'master/state-list');
	}

	masterModel.getYear = function(){
		var jsonArr = [];
		 for (var i = new Date().getFullYear(); i > 1799; i--)
			{
				 jsonArr.push({
			        value: i,
			        option: i
			    });
			}	
		jsonArrs = jsonArr;	
		//console.log(jsonArrs);
		return jsonArrs;
	}

	masterModel.disabilities = function() {
		return $http.get(baseUrl+'master/disabilities');
	}
	masterModel.getCities = function(obj){

		return $http.get(baseUrl+'master/city-list',{params:{"obj":obj}});
	} 

	masterModel.getTaluka = function(obj){
		return $http.get(baseUrl+'master/taluka-list',{params:{"obj":obj}});
	} 

	masterModel.getDistrict = function(obj){
		return $http.get(baseUrl+'master/district-list',{params:{"obj":obj}});
	} 
	

	/*masterModel.sectors = function() {
	 	return $http.get(baseUrl+'master/sectors');
	}*/
	masterModel.getDistrictsByDivisions = function(obj){
		return $http.get(baseUrl+'master/districts-by-divisions',{params:{"obj":obj}});
	}

	masterModel.getDivisions = function(obj){
		return $http.get(baseUrl+'master/divisions',{params:{"obj":obj}});
	}

	masterModel.areas = function() {
	 	return $http.get(baseUrl+'master/areas');
	}	

	masterModel.functionalAreas = function() {
	 	return $http.get(baseUrl+'master/functional-areas');
	}

	masterModel.getAutosuggetions = function(key){
		return $http.get(baseUrl+'common/autosuggest/'+key);
	}

	masterModel.postCategories = function(obj) {
	 	return $http.get(baseUrl+'master/post-categories',{params:{"obj":obj}});
	}	

	masterModel.getCountries = function(){
		return $http.get(baseUrl+'master/countries');
	}

	masterModel.getReligions = function(){
		return $http.get(baseUrl+'master/religions');
	}

	masterModel.getCastCategory = function(){
		return $http.get(baseUrl+'master/cast-categories');
	}

	masterModel.getSubCaste = function(obj){
		return $http.get(baseUrl+'master/sub-cast-categories',{params:{"obj":obj}});
	}

	masterModel.categories = function(){
		return $http.get(baseUrl+'master/categories').then(function(success, error){
			return success.data;
		});
	}

	masterModel.entranceExams = function(){
		return $http.get(baseUrl+'master/entrance-exams');
	}

	masterModel.getOrganizationName = function(){
		return $http.get(baseUrl+'master/organization-name');
	}

	masterModel.getLanguage = function(){
		return $http.get(baseUrl+'master/language');
	}
	
	masterModel.qualificationgroups = function(){
		return $http.get(baseUrl+'master/qualification-group');
	}

	masterModel.getSubCasteCategory = function(obj){
		return $http.get(baseUrl+'master/sub-cast-categories',{params:{"obj":obj}});
	}

	masterModel.getDisabilityTypes = function(obj){
		return $http.get(baseUrl+'master/disability-types',{params:{"obj":obj}});
	}

	masterModel.getSports = function(){
		return $http.get(baseUrl+'master/sports');
	}

	masterModel.getExpectedPassingYear = function(){ 
		var jsonArr = [];
		var max_year = new Date().getFullYear() + 6;
		for (var i = new Date().getFullYear(); i < max_year; i++)
		{	
			jsonArr.push({
			    value: i,
			    option: i
			});
		}	
		jsonArrs = jsonArr;	
		return jsonArrs;
	}

	masterModel.getEducationMediums = function(){
		/*return $http.get(baseUrl+'master/education-mediums').then(function(success, error){
			return success.data;
		});*/
		return $http.get(baseUrl+'master/education-mediums');
	}

	
	masterModel.getInstitutes = function(){
		return $http.get(baseUrl+'master/institutes');
	}

	masterModel.getBoards = function(){
		return $http.get(baseUrl+'master/boards');
	}

	masterModel.getTestSubjects = function(id){
		return $http.get(baseUrl+'master/test-subjects/'+id);
	}	


	masterModel.getQualifyingTestSubject = function() {
	 	return $http.get(baseUrl+'master/qualifying-test-subject');
	}

	masterModel.getNatureOfWork = function() {
	 	return $http.get(baseUrl+'master/nature-of-work');
	}

	masterModel.getCertificationBodies = function() {
	 	return $http.get(baseUrl+'master/certification-bodies');
	}

	masterModel.getSkillPrograms = function(id) {
		if(id==undefined){
			return $http.get(baseUrl+'master/skill-programs');
		}else{
			return $http.get(baseUrl+'master/skill-programs/'+id);
		}
	 	
	}
     masterModel.getSuccessStoriesList = function(){ 
		return $http.get(baseUrl+'admin/master/success-stories-list');
	}
	/*masterModel.getAdvertisementDetail= function(id){ 
		return $http.get(baseUrl+'admin/master/advertisement-detail/'+id);
	}*/
	/*masterModel.getData_contact_us = function(obj) {
	 	return $http.get(baseUrl+'master/post-categories',{params:{"obj":obj}});
	}	*/


	masterModel.getExperienceTypes = function() {
	 	return $http.get(baseUrl+'master/experience-types');
	}


	masterModel.getPostCategory = function() {
	 	return $http.get(baseUrl+'master/post-category');
	}

	masterModel.getNationalCodeOfOccupations = function() {
		return $http.get(baseUrl+'master/national-code-of-occupations');
	}

	masterModel.getLabels = function() {
	 	return $http.get(baseUrl+'master/labels');
	}

	masterModel.getMotherTongue = function() {
	 	return $http.get(baseUrl+'master/mother-tongue');
	}

	masterModel.getMillsList = function() {
	 	return $http.get(baseUrl+'master/mills');
	}

	masterModel.getNatureOfPosts = function() {
		return $http.get(baseUrl+'master/nature-of-posts');
	}

	masterModel.getNationalIndiustrialClassifications = function() {
		return $http.get(baseUrl+'master/national-indiustrial-classifications');
	}
   masterModel.getNationalIndiustrialClassifications1 = function() {
		return $http.get(baseUrl+'master/national-indiustrial-classifications1');
	}
	masterModel.getMaritalStatus = function() {
		return $http.get(baseUrl+'master/marital-status');
	}

	masterModel.getNationalities = function() {
		return $http.get(baseUrl+'master/nationalities');
	}

	masterModel.jobListedBySector = function() {
	 	return $http.get(baseUrl+'master/job-listed-by-sector');
	}
	
	masterModel.jobListedByLocation = function() {
	 	return $http.get(baseUrl+'master/job-listed-by-location');
	}

	masterModel.jobListedByEducation0_9 = function() {
	 	return $http.get(baseUrl+'master/job-listed-by-education09');
	}

	masterModel.jobListedBySkills = function(param) {
		if(param){
	 		return $http.get(baseUrl+'master/job-listed-by-skills/'+param);
	 	}else{
	 		return $http.get(baseUrl+'master/job-listed-by-skills/'+param);
	 	}
	}


	/**
	* @params int district_id
	**/
	masterModel.getOfficeAddress = function(id) {
		return $http.get(baseUrl+'master/office-address/'+id);
	}

	masterModel.LogoutCookie = function(){
		//return $http.get(baseUrl+'');
		return $http.get(baseUrl+'check-session').success(function(response){			
			//return response;
			if(response == "Fail"){				
				//$state.go('home.index', {}, { reload: true });
				//$state.go('');
				$state.go("home.index");
      			window.location.reload();
			}
	  });	
	  //return $http.get(baseUrl+'master/check-session');
	}
	masterModel.getRelatedTypes = function(obj){
		return $http.get(baseUrl+'/master/feedbacktypes',{params:{"obj":obj}});
	}
	masterModel.getBeneData = function(obj){
		return $http.get(baseUrl+'master/bene-data',{params:{"obj":obj}});
	}
	masterModel.getEmpData = function(obj){
		return $http.get(baseUrl+'master/emp-data',{params:{"obj":obj}});
	}


	return masterModel;
}]);
