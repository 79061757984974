app.factory('vacancyModel', ['$http', function($http) {
	vacancyModel = {};

	// add post vacany form
	vacancyModel.post_vacancy = function(PostVacancyData, contentType,clickedButton) {
		if(contentType==undefined){
			PostVacancyData.append('_token', csrfToken);
			PostVacancyData.append('clickedButton', clickedButton);
		}else{
			PostVacancyData._token = csrfToken;
			PostVacancyData.clickedButton = clickedButton;
		}
		console.log(PostVacancyData, 'post data'); //return;
		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'vacancy',
			method: 'POST',
			data: PostVacancyData
		});
	},


	vacancyModel.update_vacancy = function(PostVacancyData, contentType, vacancy_id) {
		PostVacancyData._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType
			},
			url: baseUrl+'vacancy/'+vacancy_id,
			method: 'POST',
			data: PostVacancyData
		})
	}

	vacancyModel.markReluctant = function(data)
	{
			data._token = csrfToken;
			return $http({
				headers: {
					'Content-Type': 'application/json',
				},
				url: baseUrl+'vacancy/markReluctant',
				method: 'POST',
				data: data
			});
	}

	//get vacancy listing
	vacancyModel.getJobs = function(pageNumber,employer_id) {
		// return $http.get(baseUrl+'vacancy',{params:{"employer_id":employer_id,"pageNumber":pageNumber}});
		var data = {};
		data._token = csrfToken;
		data.employer_id = employer_id;
		data.pageNumber = pageNumber;

		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'vacancy/index',
			method: 'POST',
			data: data
		})
	}

		vacancyModel.checkIsJfVcExpired = function(vacancy_id) {
		var data = {};
		data._token = csrfToken;
		data.vacancy_id = vacancy_id;
		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'vacancy/checkIsJfVcExpired',
			method: 'POST',
			data: data
		})
	}
	
	//get vacancy listing
	vacancyModel.getClosedJobs = function(pageNumber,employer_id) {
		// return $http.get(baseUrl+'vacancy',{params:{"employer_id":employer_id,"pageNumber":pageNumber}});
		var data = {};
		data._token = csrfToken;
		data.employer_id = employer_id;
		data.pageNumber = pageNumber;

		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'vacancy/close',
			method: 'POST',
			data: data
		})
	}

	vacancyModel.uploadSchedule = function(data){
		console.log(data);
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': undefined
			},
			url: baseUrl+'uploadSchedule',
			method: 'POST',
			data: data
		})
	}

	vacancyModel.addSchedule = function(data,id,obj){
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'addSchedule',
			method: 'POST',
			data: {data:data,id:id,obj:obj,_token:csrfToken}
		})
	}

	vacancyModel.searchVacancy = function(data){

		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/search',
			method: 'POST',
			data: data
		});
	}
vacancyModel.searchJobseekerVacancy= function(data){

		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/vacancy_search',
			method: 'POST',
			data: data
		});
	}
	// get responses from job seekers for vacancy
	vacancyModel.get_applications = function(vacancy_id) {
		return $http.get(baseUrl+'getJob_applications',{params:{"vacancy_id":vacancy_id}});
	}

	vacancyModel.dnldRecProfiles = function(data) {
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/dnld-recommended-profiles',
			method: 'POST',
			data: data
		});
	}

	// send invitation to job seeker
	vacancyModel.sent_invitation = function(Invitationdata) {
		Invitationdata._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'sent_invitation',
			method: 'POST',
			data: Invitationdata
		});
	}

	// disable vacancy
	vacancyModel.doVacancyDisabled = function(vacancy_id, vacancy_status) {
		return $http.get(baseUrl+'disable_vacancy/'+vacancy_id+'/'+vacancy_status);
	}

	// get vacancy details for edit vacancy
	vacancyModel.get_vacancy_details = function(vacancy_id) {
		return $http.get(baseUrl+'vacancy/'+vacancy_id+'/edit');
	}

	// get interview_responses for vacancy from job seeker
	vacancyModel.get_interview_responses = function() {
		return $http.get(baseUrl+'interview_responses');
	}

	vacancyModel.getRespondents = function(pageNumber,vacancy_id){
		return $http.get(baseUrl+'get_respondents_list',{params:{"pageNumber":pageNumber,"vacancy_id":vacancy_id}});
	}

	vacancyModel.getShortlistedCandidates = function(pageNumber,vacancy_id,category_id){
		return $http.get(baseUrl+'get_shortlisted_list',{params:{"pageNumber":pageNumber,"vacancy_id":vacancy_id,"category_id":category_id}});
	}
	//vacancyModel.getDropdownList = function(pageNumber,vacancy_id,category_id){
		//return $http.get(baseUrl+'get_dropdown_details',{params:{"pageNumber":pageNumber,"vacancy_id":vacancy_id,"category_id":category_id}});
	//}

	vacancyModel.getScheduledCandidates = function(pageNumber,vacancy_id){
		return $http.get(baseUrl+'get_scheduled_list',{params:{"pageNumber":pageNumber,"vacancy_id":vacancy_id}});
	}

	vacancyModel.getselectedCandidates = function(pageNumber,vacancy_id){
		return $http.get(baseUrl+'get_selected_list',{params:{"pageNumber":pageNumber,"vacancy_id":vacancy_id}});
	}
	vacancyModel.getclosedCandidates = function(pageNumber,vacancy_id){
		return $http.get(baseUrl+'get_closed_list',{params:{"pageNumber":pageNumber,"vacancy_id":vacancy_id}});
	}
	vacancyModel.getclosedCandidatesList = function(pageNumber,vacancy_id){
		return $http.get(baseUrl+'get_closed_list_vacancy',{params:{"pageNumber":pageNumber,"vacancy_id":vacancy_id}});
	}
	// vacancyModel.getpdfDetails = function(pageNumber,vacancy_id,type,data){
	// 	return $http.get(baseUrl+'get_pdf_details',{params:{"pageNumber":pageNumber,"vacancy_id":vacancy_id,"type":type,"data":data}});
	// }
	vacancyModel.pdfDetails = function (vacancy_id){
		return $http.get(baseUrl+'get_pdf_details/'+vacancy_id);
	}
	vacancyModel.search_profiles = function(data,type,id){
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/search_profiles',
			method: 'POST',
			data: {data:data,type:type,id:id,_token:csrfToken}
		});
	}

	vacancyModel.changeStatus = function(data,type,id, empId){
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/changeCandidateStatus',
			method: 'POST',
			data: {data:data,type:type,id:id, empId: empId,_token:csrfToken}
		});
	}

	vacancyModel.changeStatusjoin = function(data){
		//console.log(data+"---"+bene_id+"---"+vacancy_id);
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': undefined
			},
			url: baseUrl+'vacancy/changeStatusjoin',
			method: 'POST',
			data: data
		});
	}

	vacancyModel.fetchPlacementRecord = function(data){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/fetchPlacementRecord',
			method: 'POST',
			data: data
		});
	}


	vacancyModel.changeStatusjoined = function(data,bene_id,vacancy_id){
		//console.log(data+"---"+bene_id+"---"+vacancy_id);
		//data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/changeStatusjoined',
			method: 'POST',
			data: {data:data,bene_id:bene_id,vacancy_id:vacancy_id,_token:csrfToken}
		});
	}

	vacancyModel.checkstatus = function(bene_id,vacancy_id){
		//console.log(data+"---"+bene_id+"---"+vacancy_id);
		//data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/checkstatus',
			method: 'POST',
			data: {bene_id:bene_id,vacancy_id:vacancy_id}
		});
	}

	vacancyModel.recommendedProfile = function(pageNumber,data,reservations,criterias){
		//alert(pageNumber);
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/recommendedProfile',
			method: 'POST',
			data: {data:data,pageNumber:pageNumber,_token:csrfToken,reservations:reservations,criteria_id:criterias}
		});
	}

	vacancyModel.recommendedCount = function(data){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/recommendedCount',
			method: 'POST',
			data: data
		});
	}

	vacancyModel.downloadSelected = function(data,count){
		return $http.get(baseUrl+'downloadExcel',{params:{"vacancy_id":data}});
		//return $http(baseUrl+'vacancy/downloadExcel',{params:{"vacancy_id":data}});
	}

	vacancyModel.getEmployerResponses = function(jobseeker_id) {
		return $http.get(baseUrl+'get_employer_responses',{params:{"beneficiary_id":jobseeker_id}});
	}

	vacancyModel.getFeaturedJobs = function(){
		return $http.get(baseUrl+'feature-jobs');
	}

	vacancyModel.applyJob = function(vacancy_id, jobseeker_id, job_fair_id){
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/apply_job',
			method: 'POST',
			data: {
				vacancy_id: vacancy_id,
				jobseeker_id: jobseeker_id,
				job_fair_id: job_fair_id,
				_token:csrfToken
			}
		});
	}

	vacancyModel.viewVacancy = function(id){
		return $http.get(baseUrl+'view_vacancy/'+id);
	}

	vacancyModel.acceptRejectInterviewSchedule = function(vacancy_id,beneficiary_id,type,response,reason) {
		return $http.get(baseUrl+'accept_reject_interview_schedule',{params:{"beneficiary_id":beneficiary_id,"type":type,"vacancy_id":vacancy_id,"response":response,"reason":reason}});
	}

	vacancyModel.getRecommendedJobs = function(data,pageNumber=1){
		_data={};
		_data._token = csrfToken;
		_data.pageNumber = pageNumber;
		_data.data = data;
		console.log(pageNumber)
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'recommended_jobs',
			method: 'POST',
			data: _data
		});
	}
    vacancyModel.getOrganization = function(){
		return $http.get(baseUrl+'organization');
	}
	vacancyModel.getTotal_job_openings = function(employer_id){
		return $http.get(baseUrl+'total_job_openings/'+employer_id);
	}

	vacancyModel.vacancy_views_by_user = function(jobseeker_id,vacancy_id,employer_id){
		return $http.get(baseUrl+'view_vacancy/'+jobseeker_id+'/'+vacancy_id+'/'+employer_id);
	}

	vacancyModel.getTotal_vacancy_views = function(employer_id){
		return $http.get(baseUrl+'total_user_viewVacancy/'+employer_id);
	}

	vacancyModel.getTotal_vacancy_applications = function(employer_id){
		return $http.get(baseUrl+'total_application/'+employer_id);
	}

	vacancyModel.getTotal_vacancy_schedule = function(employer_id){
		return $http.get(baseUrl+'total_interview_schedules/'+employer_id);
	}

	vacancyModel.updateDisplayCount = function(data){
		// return data;
		// return $http.get(baseUrl+'vacancy/update-display-count');
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/display-count-update',
			method: 'POST',
			data: data
		});
	}

	vacancyModel.addVacancyOrder = function(data){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/add-vacancy-order',
			method: 'POST',
			data: data
		});
	}
   
	vacancyModel.addVacancyCriteria = function(data){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/add-vacancy-criteria',
			method: 'POST',
			data: data
		});
	}

	vacancyModel.getJobCriteria = function(vacancy_id){
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'get-job-criteria',
			method: 'POST',
			data: {vacancy_id,_token:csrfToken}
		});
	}

	vacancyModel.addVacancyReservation = function(data){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/add-vacancy-reservation',
			method: 'POST',
			data: data
		});
	}
	vacancyModel.addClosedVacancy = function(data){

		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/add-closed-vacancy',
			method: 'POST',
			data: data
		});
	}

	vacancyModel.addJobLocation = function(PostVacancyData, contentType,clickedButton) {
		if (contentType == undefined) {
			PostVacancyData.append('_token', csrfToken);
			PostVacancyData.append('clickedButton', clickedButton);
		}else{
			PostVacancyData._token = csrfToken;
			PostVacancyData.clickedButton = clickedButton;
		}
		return $http({
			headers: {
				'Content-Type': contentType
			},
			url: baseUrl+'vacancy/add-vacancy-location',
			method: 'POST',
			data: PostVacancyData
		});
	}

	vacancyModel.updateJobLocation = function(PostVacancyData, contentType,clickedButton) {
		if (contentType == undefined) {
			PostVacancyData.append('_token', csrfToken);
			PostVacancyData.append('clickedButton', clickedButton);
		}else{
			PostVacancyData._token = csrfToken;
			PostVacancyData.clickedButton = clickedButton;
		}
		return $http({
			headers: {
				'Content-Type': contentType
			},
			url: baseUrl+'vacancy/update-vacancy-location',
			method: 'POST',
			data: PostVacancyData
		});
	}

	vacancyModel.addVacancyToJobFair = function(data) {
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/add-vacancy-to-job-fair',
			method: 'POST',
			data: data
		});
	}

	vacancyModel.createOffLinePlacement = function(data) {
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/create-off-line-placement',
			method: 'POST',
			data: data
		});
	}

	vacancyModel.offLinePlacementData = function($id) {
		return $http.get(baseUrl+'vacancy/get-off-line-placement-data/'+$id);
	}
	vacancyModel.onLinePlacementData = function($id) {
		return $http.get(baseUrl+'vacancy/get-on-line-placement-data/'+$id);
	}


	vacancyModel.uploadOfflineExcelSheet = function(data) {
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': undefined
			},
			url: baseUrl+'vacancy/upload-off-line-excel-sheet',
			method: 'POST',
			data: data
		});
	}

	vacancyModel.jobfairCheck = function(vid, bene_id) {
		var data = {};
		data.vid = vid;
		data.bene_id = bene_id;
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/job-fair-check',
			method: 'POST',
			data: data
		});
	}

	vacancyModel.vacancyCriteriaCheck = function(vid, bene_id) {
		var data = {};
		data.vid = vid;
		data.bene_id = bene_id;
		data._token = csrfToken;
		return $http({
			headers: 
			{
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/vacancy-criteria-check',
			method: 'POST',
			data: data
		});
	}

	vacancyModel.CasteDetails = function (vacancy_id){
		return $http.get(baseUrl+'caste_details/'+vacancy_id);
	}

	vacancyModel.ReservationDetails = function (vacancy_id){
		return $http.get(baseUrl+'reservation_details/'+vacancy_id);
	}

	vacancyModel.delete_offline_placement = function(id) {
		var data = {};
		data._token = csrfToken;
		data.id = id;

		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/delete-offline-placement',
			method: 'POST',
			data: data
		});
	}
  	vacancyModel.offLineAddPlacement = function(data) {
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/offline-add-placement',
			method: 'POST',
			data: data
		});
	}
	vacancyModel.offLineAddPlacementData = function(id) {
		return $http.get(baseUrl+'vacancy/get-offline-add-placement-data',{params:{'id':id}});
	}
	vacancyModel.saveDate = function(id) {
		return $http.get(baseUrl+'vacancy/save-date',{params:{'id':id}});
	}
	vacancyModel.vacancyTypeCheck = function(id) {
		return $http.get(baseUrl+'vacancy/vacancy-type-check',{params:{'id':id}});
	}
	vacancyModel.isVacancyExpired= function(id) {
		return $http.get(baseUrl+'vacancy/is-vacancy-expired',{params:{'id':id}});
	}
	vacancyModel.checkAadharExist = function(id) {
		return $http.get(baseUrl+'vacancy/check-aadhar-exist',{params:{'id':id}});
	}
	vacancyModel.deleteOfflineAddPlacement = function (id){
		return $http.get(baseUrl+'vacancy/delete-offline-add-placement-data',{params:{'id':id}});
	}
	vacancyModel.checkPte = function(dataforpte) {

		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/check-pte',
			method: 'POST',
			data: dataforpte
		});
	}
	vacancyModel.getCandDetailsOfflineAddPlacement = function(regNo) {
		var data = {
			_token : csrfToken,
			regNo : regNo,
		}

		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/get-cand-details-offline-add-placement',
			method: 'POST',
			data: data
		});
	}

	return vacancyModel;

}]);