app.factory('pteVacancyModel', ['$http', function($http) {
	pteVacancyModel = {};

	//get pte vacancy listing
	pteVacancyModel.getJobs = function(pageNumber,employer_id) {
		// return $http.get(baseUrl+'vacancy',{params:{"employer_id":employer_id,"pageNumber":pageNumber}});
		var data = {};
		data._token = csrfToken;
		data.employer_id = employer_id;
		data.pageNumber = pageNumber;

		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'pteVacancy/index',
			method: 'POST',
			data: data
		})
	}

	pteVacancyModel.searchVacancy = function(data){

		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/search',
			method: 'POST',
			data: data
		});
	}

	// get responses from job seekers for vacancy
	pteVacancyModel.get_applications = function(vacancy_id) {
		return $http.get(baseUrl+'getJob_applications',{params:{"vacancy_id":vacancy_id}});
	}

	pteVacancyModel.dnldRecProfiles = function(data) {
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/dnld-recommended-profiles',
			method: 'POST',
			data: data
		});
	}

	// get interview_responses for vacancy from job seeker
	pteVacancyModel.get_interview_responses = function() {
		return $http.get(baseUrl+'interview_responses');
	}

	pteVacancyModel.getRespondents = function(pageNumber,vacancy_id){
		return $http.get(baseUrl+'get_respondents_list',{params:{"pageNumber":pageNumber,"vacancy_id":vacancy_id}});
	}

	pteVacancyModel.getShortlistedCandidates = function(pageNumber,vacancy_id,category_id){
		return $http.get(baseUrl+'get_shortlisted_list',{params:{"pageNumber":pageNumber,"vacancy_id":vacancy_id,"category_id":category_id}});
	}
	//pteVacancyModel.getDropdownList = function(pageNumber,vacancy_id,category_id){
		//return $http.get(baseUrl+'get_dropdown_details',{params:{"pageNumber":pageNumber,"vacancy_id":vacancy_id,"category_id":category_id}});
	//}

	pteVacancyModel.getScheduledCandidates = function(pageNumber,vacancy_id){
		return $http.get(baseUrl+'get_scheduled_list',{params:{"pageNumber":pageNumber,"vacancy_id":vacancy_id}});
	}

	pteVacancyModel.getselectedCandidates = function(pageNumber,vacancy_id){
		return $http.get(baseUrl+'get_selected_list',{params:{"pageNumber":pageNumber,"vacancy_id":vacancy_id}});
	}
	pteVacancyModel.getclosedCandidates = function(pageNumber,vacancy_id){
		return $http.get(baseUrl+'get_closed_list',{params:{"pageNumber":pageNumber,"vacancy_id":vacancy_id}});
	}
	pteVacancyModel.getclosedCandidatesList = function(pageNumber,vacancy_id){
		return $http.get(baseUrl+'get_closed_list_vacancy',{params:{"pageNumber":pageNumber,"vacancy_id":vacancy_id}});
	}
	// pteVacancyModel.getpdfDetails = function(pageNumber,vacancy_id,type,data){
	// 	return $http.get(baseUrl+'get_pdf_details',{params:{"pageNumber":pageNumber,"vacancy_id":vacancy_id,"type":type,"data":data}});
	// }
	pteVacancyModel.pdfDetails = function (vacancy_id){
		return $http.get(baseUrl+'get_pdf_details/'+vacancy_id);
	}
	pteVacancyModel.search_profiles = function(data,type,id){
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/search_profiles',
			method: 'POST',
			data: {data:data,type:type,id:id,_token:csrfToken}
		});
	}

	pteVacancyModel.changeStatus = function(data,type,id, empId){
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/changeCandidateStatus',
			method: 'POST',
			data: {data:data,type:type,id:id, empId: empId,_token:csrfToken}
		});
	}

	pteVacancyModel.changeStatusjoin = function(data){
		//console.log(data+"---"+bene_id+"---"+vacancy_id);
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': undefined
			},
			url: baseUrl+'vacancy/changeStatusjoin',
			method: 'POST',
			data: data
		});
	}

	pteVacancyModel.fetchPlacementRecord = function(data){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/fetchPlacementRecord',
			method: 'POST',
			data: data
		});
	}


	pteVacancyModel.changeStatusjoined = function(data,bene_id,vacancy_id){
		//console.log(data+"---"+bene_id+"---"+vacancy_id);
		//data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/changeStatusjoined',
			method: 'POST',
			data: {data:data,bene_id:bene_id,vacancy_id:vacancy_id,_token:csrfToken}
		});
	}

	pteVacancyModel.checkstatus = function(bene_id,vacancy_id){
		//console.log(data+"---"+bene_id+"---"+vacancy_id);
		//data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/checkstatus',
			method: 'POST',
			data: {bene_id:bene_id,vacancy_id:vacancy_id}
		});
	}

	pteVacancyModel.recommendedProfile = function(pageNumber,data,reservations,criterias){
		//alert(pageNumber);
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/recommendedProfile',
			method: 'POST',
			data: {data:data,pageNumber:pageNumber,_token:csrfToken,reservations:reservations,criteria_id:criterias,pte_only:search_pte}
		});
	}

	pteVacancyModel.recommendedCount = function(data){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/recommendedCount',
			method: 'POST',
			data: data
		});
	}

	pteVacancyModel.downloadSelected = function(data,count){
		return $http.get(baseUrl+'downloadExcel',{params:{"vacancy_id":data}});
		//return $http(baseUrl+'vacancy/downloadExcel',{params:{"vacancy_id":data}});
	}

	pteVacancyModel.getEmployerResponses = function(jobseeker_id) {
		return $http.get(baseUrl+'get_employer_responses',{params:{"beneficiary_id":jobseeker_id}});
	}

	pteVacancyModel.getFeaturedJobs = function(){
		return $http.get(baseUrl+'feature-jobs');
	}

	pteVacancyModel.applyJob = function(vacancy_id, jobseeker_id, job_fair_id){
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/apply_job',
			method: 'POST',
			data: {
				vacancy_id: vacancy_id,
				jobseeker_id: jobseeker_id,
				job_fair_id: job_fair_id,
				_token:csrfToken
			}
		});
	}

	pteVacancyModel.viewVacancy = function(id){
		return $http.get(baseUrl+'view_vacancy/'+id);
	}

	pteVacancyModel.acceptRejectInterviewSchedule = function(vacancy_id,beneficiary_id,type,response,reason) {
		return $http.get(baseUrl+'accept_reject_interview_schedule',{params:{"beneficiary_id":beneficiary_id,"type":type,"vacancy_id":vacancy_id,"response":response,"reason":reason}});
	}

	pteVacancyModel.getRecommendedJobs = function(data,pageNumber=1){
		_data={};
		_data._token = csrfToken;
		_data.pageNumber = pageNumber;
		_data.data = data;
		console.log(pageNumber)
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'recommended_jobs',
			method: 'POST',
			data: _data
		});
	}
    pteVacancyModel.getOrganization = function(){
		return $http.get(baseUrl+'organization');
	}
	pteVacancyModel.getTotal_job_openings = function(employer_id){
		return $http.get(baseUrl+'total_job_openings/'+employer_id);
	}

	pteVacancyModel.vacancy_views_by_user = function(jobseeker_id,vacancy_id,employer_id){
		return $http.get(baseUrl+'view_vacancy/'+jobseeker_id+'/'+vacancy_id+'/'+employer_id);
	}

	pteVacancyModel.getTotal_vacancy_views = function(employer_id){
		return $http.get(baseUrl+'total_user_viewVacancy/'+employer_id);
	}

	pteVacancyModel.getTotal_vacancy_applications = function(employer_id){
		return $http.get(baseUrl+'total_application/'+employer_id);
	}

	pteVacancyModel.getTotal_vacancy_schedule = function(employer_id){
		return $http.get(baseUrl+'total_interview_schedules/'+employer_id);
	}

	pteVacancyModel.updateDisplayCount = function(data){
		// return data;
		// return $http.get(baseUrl+'vacancy/update-display-count');
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/display-count-update',
			method: 'POST',
			data: data
		});
	}

	pteVacancyModel.addVacancyOrder = function(data){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/add-vacancy-order',
			method: 'POST',
			data: data
		});
	}
   
	pteVacancyModel.addVacancyCriteria = function(data){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/add-vacancy-criteria',
			method: 'POST',
			data: data
		});
	}

	pteVacancyModel.addVacancyReservation = function(data){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/add-vacancy-reservation',
			method: 'POST',
			data: data
		});
	}
	pteVacancyModel.addClosedVacancy = function(data){

		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/add-closed-vacancy',
			method: 'POST',
			data: data
		});
	}

	pteVacancyModel.addJobLocation = function(PostVacancyData, contentType,clickedButton) {
		if (contentType == undefined) {
			PostVacancyData.append('_token', csrfToken);
			PostVacancyData.append('clickedButton', clickedButton);
		}else{
			PostVacancyData._token = csrfToken;
			PostVacancyData.clickedButton = clickedButton;
		}
		return $http({
			headers: {
				'Content-Type': contentType
			},
			url: baseUrl+'vacancy/add-vacancy-location',
			method: 'POST',
			data: PostVacancyData
		});
	}

	pteVacancyModel.updateJobLocation = function(PostVacancyData, contentType,clickedButton) {
		if (contentType == undefined) {
			PostVacancyData.append('_token', csrfToken);
			PostVacancyData.append('clickedButton', clickedButton);
		}else{
			PostVacancyData._token = csrfToken;
			PostVacancyData.clickedButton = clickedButton;
		}
		return $http({
			headers: {
				'Content-Type': contentType
			},
			url: baseUrl+'vacancy/update-vacancy-location',
			method: 'POST',
			data: PostVacancyData
		});
	}

	pteVacancyModel.addVacancyToJobFair = function(data) {
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/add-vacancy-to-job-fair',
			method: 'POST',
			data: data
		});
	}

	pteVacancyModel.createOffLinePlacement = function(data) {
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/create-off-line-placement',
			method: 'POST',
			data: data
		});
	}

	pteVacancyModel.offLinePlacementData = function($id) {
		return $http.get(baseUrl+'vacancy/get-off-line-placement-data/'+$id);
	}
	pteVacancyModel.onLinePlacementData = function($id) {
		return $http.get(baseUrl+'vacancy/get-on-line-placement-data/'+$id);
	}


	pteVacancyModel.uploadOfflineExcelSheet = function(data) {
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': undefined
			},
			url: baseUrl+'vacancy/upload-off-line-excel-sheet',
			method: 'POST',
			data: data
		});
	}

	pteVacancyModel.jobfairCheck = function(vid, bene_id) {
		var data = {};
		data.vid = vid;
		data.bene_id = bene_id;
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/job-fair-check',
			method: 'POST',
			data: data
		});
	}

	pteVacancyModel.vacancyCriteriaCheck = function(vid, bene_id) {
		var data = {};
		data.vid = vid;
		data.bene_id = bene_id;
		data._token = csrfToken;
		return $http({
			headers: 
			{
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/vacancy-criteria-check',
			method: 'POST',
			data: data
		});
	}

	pteVacancyModel.CasteDetails = function (vacancy_id){
		return $http.get(baseUrl+'caste_details/'+vacancy_id);
	}

	pteVacancyModel.ReservationDetails = function (vacancy_id){
		return $http.get(baseUrl+'reservation_details/'+vacancy_id);
	}

	pteVacancyModel.delete_offline_placement = function(id) {
		var data = {};
		data._token = csrfToken;
		data.id = id;

		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/delete-offline-placement',
			method: 'POST',
			data: data
		});
	}
  	pteVacancyModel.offLineAddPlacement = function(data) {
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/offline-add-placement',
			method: 'POST',
			data: data
		});
	}
	pteVacancyModel.offLineAddPlacementData = function(id) {
		return $http.get(baseUrl+'vacancy/get-offline-add-placement-data',{params:{'id':id}});
	}
	pteVacancyModel.saveDate = function(id) {
		return $http.get(baseUrl+'vacancy/save-date',{params:{'id':id}});
	}
	pteVacancyModel.vacancyTypeCheck = function(id) {
		return $http.get(baseUrl+'vacancy/vacancy-type-check',{params:{'id':id}});
	}
	pteVacancyModel.isVacancyExpired= function(id) {
		return $http.get(baseUrl+'vacancy/is-vacancy-expired',{params:{'id':id}});
	}
	pteVacancyModel.checkAadharExist = function(id) {
		return $http.get(baseUrl+'vacancy/check-aadhar-exist',{params:{'id':id}});
	}
	pteVacancyModel.deleteOfflineAddPlacement = function (id){
		return $http.get(baseUrl+'vacancy/delete-offline-add-placement-data',{params:{'id':id}});
	}
	pteVacancyModel.getCandDetailsOfflineAddPlacement = function(regNo) {
		var data = {
			_token : csrfToken,
			regNo : regNo,
		}

		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'vacancy/get-cand-details-offline-add-placement',
			method: 'POST',
			data: data
		});
	}

	return pteVacancyModel;

}]);