app.factory('news1Model', ['$http','$stateParams','$location', function($http,$stateParams,$location) {
	news1Model = {};

    /* News1Model.getNewsByName = function(newsname){
		return $http.get(baseUrl+'newsname/'+ newsname);
	}*/

	 news1Model.getNews1 = function(){
		return $http.get(baseUrl+'get_news1');
	}
	 news1Model.getSingleNews1 = function(id){
		return $http.get(baseUrl+'single_news1/'+id);
	}
/*
	pagesModel.getSingleNews = function(id){
		return $http.get(baseUrl+'single_news/'+id);
	}

	pagesModel.getPolicy = function(){
		return $http.get(baseUrl+'policy');
	}*/
	return news1Model;
}]);