app.factory('pagesModel', ['$http','$stateParams','$location', function($http,$stateParams,$location) {
	pagesModel = {};

	pagesModel.getPageByName = function(pagesname){
		return $http.get(baseUrl+'pages/'+ pagesname);
	}

	pagesModel.getNews = function(){
		return $http.get(baseUrl+'get_news');
	}

	pagesModel.getSingleNews = function(id){
		return $http.get(baseUrl+'single_news/'+id);
	}

	pagesModel.getAdvertisementDetail = function(id){
		console.log(id);
		return $http.get(baseUrl+'advertisement_detail/'+id);
	}

	pagesModel.getPolicy = function(){
		return $http.get(baseUrl+'policy');
	}
	return pagesModel;
}]);